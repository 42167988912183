/*
--- 01 TYPOGRAPHY SYSTEM

- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weights
default: 400
medium: 500
semi bold: 600
bold: 700
- Line heights
Default: 1 
1.05
paragraph: 1.6 (hero description)
- Spacing system: (px)

2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

--- 02 COLOURS

- Primary Color: #e67e22

- Tints: t1: (herosection) #fdf2e9--
- Shades: #cf711f;
- Accents: 
- Greys:
#555
#333
--- 03 IMAGES:

sources: unsplash.com
people images: UI faces 
app screens : 

--- 04 SHADOWS

--- 05 Border radius 

--- 06 white space
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128




*/

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  /*font-size: 10px;*/
  font-size: 62.5%; /* percentange of users's browser font-size setting*/
}

body {
  font-family: "Rubik" sans-serif;
  line-height: 1;
  font-weight: 400;
  color: #555;
}
/************************************/
/* GENERAL REUSABLE COMPONENTS */
/************************************/
.heading-primary {
  font-size: 5rem;
  font-weight: 700rem;
  line-height: 1.05;
  color: #333;
  letter-spacing: -0.5px;
  margin-bottom: 3.2rem;
}
.colortag {
  color: #e67e22;
}

.btn:link,
.btn:visited {
  display: inline-block;
  background-color: #e67e22;
  color: #fff;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 600;
  padding: 1.6rem 3.2rem;
  border-radius: 9px;
  /* put transition on original state*/
  transition: all 0.3s;
}
.btn--full:link,
.btn--full:visited {
  background-color: #e67e22;
  color: #fff;
}

.btn--full:hover,
.btn--full:active {
  background-color: #cf711f;
}

.btn--outline:link,
.btn--outline:visited {
  background-color: #fff;
  color: #555;
}

.btn--outline:hover,
.btn--outline:active {
  background-color: #fdf2e9;
  /* trick to add border inside*/
  box-shadow: inset 0 0 0 3px #fff;
}

.margin-rihgt-sm {
  margin-right: 1.6rem !important;
}

/************************************/
/* HEADER SECTION */
/************************************/
.header {
  font-family: "Rubik" sans-serif;
  line-height: 1;
  font-weight: 400;
  color: #555;

  background-color: #fae5d3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*Because we want header to be stick later*/
  height: 9.6rem;
  padding: 0 4.8rem;
}
.logo {
  height: 5rem;
}
/************************************/
/* NAVIGATION SECTION */
/************************************/
.main-nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 4rem;
}

.main-nav-link:link,
.main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 1.8rem;
  transition: all 0.3s;
}

.main-nav-link:hover,
.main-nav-link:active {
  color: #cf711f;
}

.main-nav-link.nav-cta:link,
.main-nav-link.nav-cta:visited {
  padding: 1.2rem 2.4rem;
  border-radius: 9px;
  color: #fff;
  background-color: #e67e22;
}

.main-nav-link.nav-cta:hover,
.main-nav-link.nav-cta:active {
  background-color: #cf711f;
}
*:focus {
  outline: none;
  box-shadow: 0 0 0 0.8rem rga(230, 125, 34, 0.56);
}

/* STICKY NAVIGATION */

.sticky {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 8rem;
  padding-top: 0;
  padding-bottom: 0;
  background-color: rgba(255, 255, 255, 0.97);
  z-index: 999;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);
}

.sticky .Section-hero {
  margin-top: 9.6rem;
}

/************************************/
/* HERO SECTION */
/************************************/

.Section-hero {
  background-color: #fae5d3;
  padding: 4.8rem 0 9.6rem 0;
}

.hero {
  max-width: 130rem;
  margin: 0 auto;
  padding: 0 3.2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9.6rem;
  align-items: center;
}

.hero-description {
  font-size: 2.5rem;
  line-height: 1.6;
  margin-bottom: 3.2rem;
}

.hero-image {
  width: 100%;
}

.mentored-students {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-top: 8rem;
}
.student-imgs {
  display: flex;
}
.student-imgs img {
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 50%;
  margin-right: -1.6rem;
  border: 3px solid #fdf2e9;
}

.student-imgs img:last-child {
  margin: 0;
}

.student-img-text {
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: 1.55rem;
  margin-left: 2.5rem;
}

.student-img-text span {
  color: #cf711f;
  font-weight: 700;
}

/************************************/
/* featured in SECTION */
/************************************/

.section-featured-in#section-featured-in-id {
  padding: 0.5rem 0;
}

.featuredin-text#featuredin-text-id {
  font-size: 1.6rem !important;
  text-align: center;
  text-transform: uppercase !important;
  letter-spacing: 0.75px;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.logo-pics {
  display: flex;
  justify-content: center;
}
.featuredin-img {
  width: 20%;
}

/************************************/
/* Learn more  SECTION */
/************************************/
.vplayer {
  /* top / right&left / bottom */
  padding: 2rem 0 4rem;
}

.vplayer-text {
  font-family: "Rubik" sans-serif;
  font-size: 1.6rem !important;
  text-align: center;
  text-transform: uppercase !important;
  letter-spacing: 0.75px;
  font-weight: 500;
  margin-bottom: 4rem;
  margin-top: 1rem;
  color: #e67e22;
}
.theplayer {
  display: flex;
  justify-content: center;
}
/**************************/
/* SERVICES SECTION */
/**************************/

.section-services {
  /* top / horizontal / left */
  padding: 3rem 0 12.8rem;
}

.subheading#subheading-id {
  display: block;
  font-family: "Rubik" sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  color: #e67e22;
  text-transform: uppercase;

  margin-bottom: 1.6rem;
  letter-spacing: 0.75px;
}

.heading-secondary {
  font-family: "Rubik" sans-serif;
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 9.6rem;
}

.grid-3-cols {
  margin-top: 10rem;
  display: grid;
  /* 2/3 = 66.6% + 1/3 = 33.3% */
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5rem;
}

.services-im#servicesimg-id {
  width: 100%;
}

.service-title#service-title-id {
  font-size: 2.1rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 3.2rem;
}

.service-attributes#service-attributes-id {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
}

.service-attributes#service-attributes-id li:before {
  content: "★";
  display: inline-block;
  margin-right: 5px;
  font-size: 2.2rem;
  line-height: 0.5rem;
  color: #e67e22;
}

.service-attribute#service-attribute-id {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  color: #333;
  font-weight: 400;
}

.services#services-id {
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.4s;
}

.services:hover {
  transform: translateY(-1.2rem);
  box-shadow: 0 3.2rem 6.4rem rgba(0, 0, 0, 0.2);
}

.services-content#services-content-id {
  padding: 2.5rem 3rem 3rem 3rem;
}

.s-text#s-text-id {
  font-size: 2.4rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 3.2rem;
}
.list-services#list-services-id {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
}

.list-services#list-services-id li:before {
  content: "✔️";
  display: inline-block;
  margin-right: 5px;
  font-size: 3rem;
  line-height: 0.5rem;
  color: #e67e22;
}

.comingsoon-service {
  font-size: 2rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

/**************************/
/* CTA SECTION */
/**************************/

.section-cta {
  padding: 9.6rem 0;
}
.container {
  max-width: 130rem;
  padding: 0 3.2rem;
  margin: 0 auto;
}
.cta {
  display: grid;
  grid-template-columns: 2fr 1fr;
  background-color: #e67e22;
  box-shadow: 0 2.4rem 4.5rem rgba(0, 0, 0, 0.35);
  border-radius: 11px;
  overflow: hidden;
}

.cta-text-box {
  padding: 6.2rem;
  font-family: "Rubik" sans-serif;
  font-weight: 400;
  color: #2e1907;
}

.cta .heading-secondary {
  color: #2e1907;
  margin-bottom: 3.2rem;
  font-size: 3.66rem;
}

.cta-text {
  font-size: 1.8rem;
  line-height: 1.8;
  margin-bottom: 3.8rem;
}
.cta-img-box {
  background-image: linear-gradient(
    to right bottom,
    rgba(235, 151, 78, 0.35),
    rgba(230, 125, 34, 0.35)
  );
  background-image: url("../public/content/img/ctapic-linkedin-soulutions.png");
  background-size: cover;

  background-position: center;
}

.cta-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3.2rem;
  row-gap: 2.4rem;
}

.cta-form label {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
}

.cta-form input {
  width: 100%;
  padding: 1.2rem;
  font-size: 1.8rem;
  font-family: inherit;
  color: inherit;
  border: none;

  background-color: #fdf2e9;
  border-radius: 9px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.cta-form input::placeholder {
  color: #aaa;
}

.cta-form select {
  width: 100%;
  padding: 1.2rem;
  font-size: 1.8rem;
  font-family: inherit;
  color: inherit;
  border: none;

  background-color: #fdf2e9;
  border-radius: 9px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.cta-form button {
  display: inline-block;
  width: 100%;
  padding: 1.2rem;
  font-size: 1.8rem;
  font-family: inherit;
  font-weight: 600;
  color: inherit;
  border: none;

  background-color: #45260a;
  color: #fdf2e9;
  border-radius: 9px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  align-items: center;
  transition: all 0.3s;
}

.btn--form:hover {
  background-color: #fff;
  color: #555;
}

.cta *:focus {
  outline: none;
  box-shadow: 0 0 0 0.8rem rga(230, 125, 34, 0.56) !important;
}
/**************************/
/* FOOTER */
/**************************/

.footer-line {
  padding: 12.8rem 0;
  border-top: 1px solid #eee;
}
.container-footer {
  max-width: 130rem;
  padding: 0 3.2rem;
  margin: 0 auto;
}

.grid--footer {
  grid-template-columns: 1.5fr 1.5fr;
}
.social-links {
  flex: 3;
  display: flex;
  justify-content: flex-end;
}
